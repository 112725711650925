import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import EducationBanner from "../components/educationBanner";

class EducationWorkingGroup extends Component {
    render() {
        return (
            <Layout headerElement={<EducationBanner />}>
                <SEO title="Education Working Group" />
                <section id="section3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="img-box"><img className="img--layout" src="https://demo.artureanec.com/html/helpo/img/blog_bg-2.png" alt="img" style={{top: '34%'}} />
                                    <div className="img-box__img">
                                        <img alt="education" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/drum.jpg" className="img--bg m-b-10" style={{height: '42%', marginTop: 125}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <h2 className="text-medium"><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/okuafo-pa-small.png" width="40" /> Education</h2>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px'}}>
                                    <li>African Nova Scotian learners are experiencing systemic racism, anti-Black racism and lack of social inclusion</li>
                                    <li>Achievement gap in literacy and math persists</li>
                                    <li>ANS learners are disproportionately subjected to suspensions and additional factors, such as insufficient representation in instructional practices and assessment that contribute to “push out”</li>
                                    <li>Inadequate distribution of funds and resources are a major challenge to foster equitable opportunities for African Nova Scotian learners</li>
                                </ul>
                                <div className="widget-title recommended" style={{textDecoration: 'underline'}}>Recommendations</div>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                    <li>Address endemic educational concerns, leading to youth (18-30) and older worker (31-65) unemployment</li>
                                    <li>Sustainable access to training support once outside the secondary school system</li>
                                    <li>Develop and implement an: </li>
                                    <li>Develop an African Nova Scotian Education Success Strategy</li>
                                    <li className="d-block">
                                        <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                            <li>Informed by research to address overrepresentation on Individual Program Plans
(IPPs), less than optimal levels of engagement in public school and postsecondary learning; disaggregate the data to develop a comprehensive analysis</li>
                                            <li>Enhance and support community-based/ grassroots programs and initiatives that provide culturally relevant awareness which allow African Nova Scotian learners to develop a sense of identity</li>
                                            <li>Ensure engagement of the African Nova Scotian communities as collaborators in this approach</li>
                                        </ul>
                                    </li>
                                    <li>Fully implement recommendations set out in the 1994 BLAC report and 2009 Reality Check</li>
                                </ul>
                                <div className="clear"></div>
                                <div className="seperator"><i className="fa fa-gavel"></i></div>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        );
    }
}

export default EducationWorkingGroup