import React, { Component, Fragment } from "react"

interface Props {
  siteTitle: string;
}

class EducationBanner extends Component<{}> {
  constructor(props: Props) {
    super(props);

  }

  render() {
    return (
      <Fragment>
        <div id="slider" className="inspiro-slider dots-creative" style={{height: 600}}>
          <div className="slide kenburns" style={{  
            backgroundImage: "url(" + "https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/education-bg.png" + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}>
              <div className="bg-overlay"></div>
                <div className="container-wide">
                    <div className="container-fullscreen">
                        <div className="text-middle">
                            <div className="heading-text text-light col-lg-12">
                                <h2 className="font-weight-800 text-center"><span className="working-group-headers">Education Working Group</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        </div>
        <section className="reservation-form-over no-padding">
            <div className="container">
                <form action="#" method="post">
                    <div className="row justify-content-center reservation-form">


                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <div className="input-group">
                                    <h4 className="text-center">If you are interested in joining the working group contact the office via email or phone</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
      </Fragment>
    );
  }
}

export default EducationBanner
